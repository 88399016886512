var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title has-text-primary-dark"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(String(_vm.$t("general.plant"))))+" ")])]),_c('div',{staticClass:"column has-text-right"},[_c('div',{staticClass:"buttons is-right"},[_c('router-link',{attrs:{"to":{ name: 'PlantList' },"custom":""}},[(_vm.$route.name === 'PlantMyPlants')?_c('b-button',{attrs:{"type":"is-info"}},[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-list"})]),_c('span',[_vm._v(_vm._s(_vm.$t("components.table.list_view")))])])]):_vm._e()],1),_c('router-link',{attrs:{"to":{ name: 'PlantMyPlants' },"custom":""}},[(_vm.$route.name === 'PlantList')?_c('b-button',{attrs:{"type":"is-info"}},[_c('span',{staticClass:"icon-text"},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-table-cells-large"})]),_c('span',[_vm._v(_vm._s(_vm.$t("components.table.grid_view")))])])]):_vm._e()],1),(_vm.$route.name === 'PlantUpdate')?_c('b-button',{attrs:{"type":"is-danger"},on:{"click":function($event){_vm.onRemove(
              Number(_vm.$route.params.id),
              _vm.resource,
              _vm.redirectRouteNameOnDeleteSuccess,
              String(_vm.$tc('general.plant', 1))
            )}}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(_vm.$route.name !== 'PlantCreate')?_c('b-button',{attrs:{"type":"is-success"},on:{"click":_vm.onAdd}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fa-solid fa-plus"})])]):_vm._e()],1)])]),_c('hr'),_c('router-view',{attrs:{"redirectRouteNameOnDeleteSuccess":_vm.redirectRouteNameOnDeleteSuccess},on:{"archive":function($event){_vm.onArchive(
        Number($event.id),
        _vm.resource,
        _vm.redirectRouteNameOnDeleteSuccess,
        String(_vm.$tc('general.plant', 1))
      )},"remove":function($event){_vm.onRemove(
        Number($event.id),
        _vm.resource,
        _vm.redirectRouteNameOnDeleteSuccess,
        String(_vm.$tc('general.plant', 1))
      )}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }